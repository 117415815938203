import React, { useEffect, useState } from "react";
import { useRef } from "react";
import { Modal } from "react-bootstrap";
import { RxCross1 } from "react-icons/rx";

import "react-time-picker/dist/TimePicker.css";
import "react-clock/dist/Clock.css";

import "react-date-picker/dist/DatePicker.css";
import "react-calendar/dist/Calendar.css";

import "./style.css";
import { useDispatch, useSelector } from "react-redux";
import {
  useFetchObservaionTypeList,
  useFetchSiteSgmntListByProject,
} from "../../../../queries/useFetchOptions";
import {
  createObservaion,
  createObservationLocally,
} from "../../../../actions/projectActions";

// form Validation imports
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { formatDate5 } from "../../../../utils/DateFormater";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import unavailableMapPlaceHolder from "../../../../assets/images/unavailableMapPlaceholder.svg";
import uploadImagePlaceHolder from "../../../../assets/images/uploadImagePlaceholder.svg";
import {
  useFetchMiniCardObservaionList,
  useFetchObservaionList,
  useFetchUnvalidatedObservaionList,
} from "../../../../queries/useMapQueriesHook";
import { useTranslation } from "react-i18next";

import ObservationTypePill from "../../ObservationTypePill";
import { obsertionTypes } from "../../../../constants/observationsTypes";
import {
  useCreateNewFemaleEncounter,
  useCreateObservationStore,
} from "../../../../lib/createObs/useCreateObservationData";
import useCreateValidation from "../../../../lib/createObs/useCreateValidation";
import ConfirmExit from "./createMolecules/ConfirmExit";
import ImagesUpload from "./createMolecules/ImagesUpload";
import { handleSetObservationType } from "../../../../lib/createObs/createObsFuncs";

import CoordinatesInputs from "./createMolecules/CoordinatesInputs";
import GroupFamSpecie from "./createMolecules/GroupFamSpecie";
import SiteSegmentInputs from "./createMolecules/SiteSegmentInputs";

import CreateNewFemaleEncounter from "./createMolecules/obsDynamicFields/CreateNewFemaleEncounter";
import ModalsMap from "../../modalsMolecules/ModalsMap";
import DateTimeSelector from "../../modalsMolecules/DateTimeSelector";
import CreateNewNest from "./createMolecules/obsDynamicFields/CreateNewNest";
import { handleFormatData } from "../../../../lib/createObs/formatDataForCreate";
import CustomSuccess from "../../../Alerts/CustomSuccess";
import NewFemaleCreate from "../newFemale/NewFemaleCreate";

const userInfo = JSON.parse(localStorage.getItem("userInfo"));

dayjs.extend(customParseFormat);

const CreateObservation = ({ handleHideModalCreateModal }) => {
  // validation
  const {
    localObsTypeValidation,
    setLocalLatValidation,
    setLocalObsTypeValidation,
    setLocalNestHealthValidation,
    setLocalReasonForLostValidation,
    setLocalSpeciesState,
    setLocalGroupValidation,
    setLocalSiteValidation,
    setLocalFamilyValidation,
    setLocalNestIDValidation,

    setLocalNestRelocateSiteValidation,
    setLocalSuspectedNestCreationDateValidation,

    setLocalNestStatusValidation,
    setLocalNestEstHatchDateValidation,
    setLocalNestEstExhumDateValidation,

    setLocalFemEncLengthValidation,
    setLocalFemEncWidthValidation,

    setLocalNotesValidation,
    resetAllValidations,
  } = useCreateValidation();

  // new Form data management
  const {
    newDate,
    currentTime,
    numberOfEggs,
    seconds,
    newLocalLat,
    newLocalLong,

    obsTypeState,
    groupeState,
    familyState,
    speciesState,
    siteState,
    relocateSite,
    segmentState,
    deadOrAlive,
    images,
    imageData,
    nestID,
    relocateSegment,

    setImages,
    setImageData,
    nestHighTideDist,
    nestStatus,
    nestHealth,
    nestRelocation,
    isNewNestIntact,
    reasonForLost,
    suspectedNestCreationDate,
    doesNewNestNeedRelocation,
    nestEstHatchDate,
    nestEstExhumDate,
    femEncTagLeft,
    femEncTagRight,
    femEncLength,
    femEncWidth,
    crawlWidth,
    notes,
    isFemalePresent,
    displaySpecies,
    displayGroupe,
    displayFamily,
    displaySegment,
    displaySite,
    displayObserVationType,
    setSeconds,
    setNewLocalLat,
    setNewLocalLong,
    setCrawlWidth,
    setObsTypeState,
    setDisplayObserVationType,

    setIsFemalePresent,
    resetCreateValues,
    setNotes,
  } = useCreateObservationStore();

  const { t } = useTranslation();

  const pId = useSelector((state) => state.projectId);
  const { localProjectId } = pId;
  const { queryRefetchObsList } = useFetchUnvalidatedObservaionList({
    id: localProjectId,
  });
  const { queryRefetchValidObsList } = useFetchObservaionList({
    id: localProjectId,
  });
  const { miniCardQueryRefetchObsList } = useFetchMiniCardObservaionList({
    id: localProjectId,
  });
  const { siteSegmentList } = useFetchSiteSgmntListByProject(localProjectId);
  const successMessage = useSelector((state) => state.setSuccessMessage);
  const { createObsSuccessNotif } = successMessage;

  useEffect(() => {
    var x = document.getElementById("demo");
    function getLocation() {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(showPosition);
      } else {
        x.innerHTML = "Geolocation is not supported by this browser.";
      }
    }
    getLocation();
  }, []);

  // validation

  const validationSchema = yup.object().shape({
    coords: yup.string().required(`${t("required")}`),
    site: yup.string(),
    segment: yup.string(),
    observationType: yup.string().required(`${t("required")}`),
    groupe: yup.string().required(`${t("required")}`),
    family: yup.string().required(`${t("required")}`),
    specie: yup.string().required(`${t("required")}`),
  });
  const {
    femaleSuccessCreation,
    setFemaleSuccessCreation,
    setFemaleCreated,
    femaleCreated,
  } = useCreateNewFemaleEncounter();
  const {
    register,

    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: "onChange",
    reValidateMode: "onChange",
    resolver: yupResolver(validationSchema),
  });

  function getRandomNumber() {
    let seconds = Math.floor(Math.random() * 60)
      .toString()
      .padStart(2, "0");
    setSeconds(seconds);
  }
  useEffect(() => {
    getRandomNumber();
  }, []);

  const { observationList } = useFetchObservaionTypeList();

  const [user, setuser] = useState();
  useEffect(() => {
    if (userInfo) {
      setuser(userInfo.data.email);
    }
  }, []);

  const dispatch = useDispatch();

  // Upload images function

  const [showUploadUI, setShowUploadUI] = useState(false);

  let currentProject = JSON.parse(localStorage.getItem("selectedProject"));
  // setSelectedProject(currentProject);

  const onSubmit = (data) => {
    const newData = handleFormatData({
      projectId: currentProject && currentProject.id,
      newDate,
      currentTime,
      seconds,
      newLocalLat,
      newLocalLong,
      obsTypeState,
      groupeState,
      familyState,
      speciesState,
      siteState,
      relocateSite,
      relocateSegment,
      numberOfEggs,
      doesNewNestNeedRelocation,
      segmentState,
      deadOrAlive,
      images,
      imageData,
      nestID,
      nestHighTideDist,
      nestStatus,
      nestHealth,
      nestRelocation,
      reasonForLost,
      suspectedNestCreationDate,
      nestEstHatchDate,
      nestEstExhumDate,
      femEncTagLeft,
      femEncTagRight,
      femEncLength,
      femEncWidth,
      crawlWidth,
      notes,
    });
    console.log(newData, "notes");

    // Local project update
    const timeStr = currentTime + `:${seconds}`;
    const dateStr = convertedDate;

    // Splitting time into hours, minutes, and seconds
    const [hours, minutes, secondss] = timeStr.split(":");

    // Splitting date into year, month, and day
    const [year, month, day] = dateStr.split("-");

    // Setting the desired timezone
    // Adjusting the UTC offset
    const dt = new Date(
      Date.UTC(year, month - 1, day, hours, minutes, secondss)
    );

    // Creating the final object
    const localdateObject = {
      date: dt.toISOString().slice(0, 23),
      timezone_type: 3,
      timezone: "UTC",
    };

    const updtedObservationData = {
      id: "",
      projectId: currentProject && currentProject.id,
      projectName: currentProject && currentProject.name,
      idInaturalist: null,
      coordX: newLocalLat,
      coordY: newLocalLong,
      note: "",
      alpha: "",
      collector: "",
      images: {
        img1: "",
        img2: "",
        img3: "",
        img4: "",
      },
      dead: deadOrAlive,
      status: 0,
      TypeObservationId: obsTypeState,
      TypeObservation: displayObserVationType,
      specieId: speciesState,
      specie: displaySpecies,
      familyId: familyState,
      family: displayFamily,
      groupId: groupeState,
      group: displayGroupe,
      user: user,
      date: localdateObject,
      updatedate: null,
      segment: displaySegment,
      site: displaySite,
    };

    dispatch(createObservaion(newData));
    dispatch(createObservationLocally(updtedObservationData));
    handleHideModalCreateModal();
    resetCreateValues();
    resetAllValidations();
    setFemaleCreated(false);
  };

  useEffect(() => {
    if (createObsSuccessNotif && createObsSuccessNotif === true) {
      queryRefetchObsList();

      queryRefetchValidObsList();
      miniCardQueryRefetchObsList();
    }
  }, [createObsSuccessNotif]);

  useEffect(() => {
    if (createObsSuccessNotif && createObsSuccessNotif === true) {
      setFemaleCreated(true);
      setFemaleSuccessCreation(true);
    }
    if (femaleSuccessCreation) {
      setTimeout(() => {
        setFemaleSuccessCreation(false);
      }, 3000);
    }
  }, [
    createObsSuccessNotif,
    setFemaleCreated,
    femaleSuccessCreation,
    setFemaleSuccessCreation,
  ]);

  const handleCreateObs = () => {
    const commonFields = {
      newLocalLat,
      newLocalLong,
      obsTypeState,
      speciesState,
      siteState,
    };

    const validateCommonFields = () => {
      return Object.entries(commonFields).every(([key, value]) => !!value);
    };

    const validateNestFields = () => {
      return (
        nestID &&
        suspectedNestCreationDate &&
        nestEstHatchDate &&
        nestEstExhumDate
      );
    };

    const handleValidationErrors = () => {
      Object.entries(commonFields).forEach(([key, value]) => {
        if (!value) {
          switch (key) {
            case "newLocalLat":
              setLocalLatValidation(true);
              break;
            case "obsTypeState":
              setLocalObsTypeValidation(true);
              break;
            case "speciesState":
              setLocalSpeciesState(true);
              break;
            case "siteState":
              setLocalSiteValidation(true);
              break;
            default:
              console.warn(`Unexpected field: ${key}`);
              break;
          }
        }
      });

      setLocalGroupValidation(!groupeState);
      setLocalFamilyValidation(!familyState);
      setLocalNotesValidation(!notes);
    };

    const validateNestObservation = () => {
      if (
        displayObserVationType === obsertionTypes.nest.VE ||
        displayObserVationType === obsertionTypes.nest.VF
      ) {
        setLocalNestIDValidation(!nestID);
        setLocalNestStatusValidation(!nestStatus);
        setLocalNestHealthValidation(!nestHealth);
        setLocalReasonForLostValidation(!isNewNestIntact && !reasonForLost);

        if (nestRelocation) {
          setLocalNestRelocateSiteValidation(!relocateSite);
        }

        if (!isFemalePresent) {
          setLocalSuspectedNestCreationDateValidation(
            !suspectedNestCreationDate
          );
        }

        setLocalNestEstHatchDateValidation(!nestEstHatchDate);
        setLocalNestEstExhumDateValidation(!nestEstExhumDate);
      }
    };

    const validateFemaleObservation = () => {
      if (
        displayObserVationType === obsertionTypes.female.VE ||
        displayObserVationType === obsertionTypes.female.VF
      ) {
        setLocalFemEncLengthValidation(femEncLength === 0);
        setLocalFemEncWidthValidation(femEncWidth === 0);
      }
    };

    if (!validateCommonFields()) {
      handleValidationErrors();
      return;
    }

    if (obsTypeState === "1") {
      if (!validateNestFields()) {
        validateNestObservation();
        return;
      }
      // Check if nestStatus or nestHealth is null
      if (!nestStatus && !nestHealth) {
        onSubmit();
        return;
      }
      if (isNewNestIntact) {
        onSubmit();
        return;
      }

      if (reasonForLost && !doesNewNestNeedRelocation) {
        onSubmit();
        return;
      }

      if (reasonForLost && doesNewNestNeedRelocation && relocateSite) {
        onSubmit();
        return;
      }
    } else {
      onSubmit();
      return;
    }

    validateNestObservation();
    validateFemaleObservation();
    setConfirmExit(false);
  };

  // exit box
  const [confirmExit, setConfirmExit] = useState(false);
  /**
   * Handles exiting the edit mode for the observation creation modal.
   * This function resets the create values, resets all validations, and hides the modal.
   */
  const handleExitEdit = () => {
    setConfirmExit(false);
    setFemaleCreated(false);
    resetCreateValues();
    resetAllValidations();
    handleHideModalCreateModal();
  };
  const handleConfirmExit = () => {
    setConfirmExit(false);
  };

  // input tracker
  /**
   * Handles the logic for exiting the edit mode of the observation creation modal.
   * If there are unsaved changes (`changeTracker` is true), it will display a confirmation modal.
   * Otherwise, it will reset the create values, reset all validations, and hide the modal.
   */
  const [changeTracker, setChangeTracker] = useState(false);
  const handleShowConfirmModal = () => {
    if (changeTracker) {
      setConfirmExit(true);
    } else {
      handleExitEdit();
    }
  };

  /**
   * Callback function that updates the state with the user's current latitude and longitude coordinates obtained from the browser's geolocation API.
   *
   * @param {Object} position - An object containing the user's latitude and longitude coordinates.
   * @param {number} position.coords.latitude - The user's current latitude.
   * @param {number} position.coords.longitude - The user's current longitude.
   */
  function showPosition(position) {
    if (!newLocalLat || !newLocalLong) setNewLocalLat(position.coords.latitude);
    setNewLocalLong(position.coords.longitude);
  }

  /**
   * Retrieves the user's current location using the browser's geolocation API.
   * If the browser supports geolocation, it calls the `showPosition` function with the latitude and longitude coordinates.
   * If the browser does not support geolocation, it returns a message indicating that geolocation is not supported.
   */
  useEffect(() => {
    function getLocation() {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(showPosition);
      } else {
        return "Geolocation is not supported by this browser.";
      }
    }
    getLocation();
  }, []);

  const cordRef = useRef();
  const modalRef = useRef(null);

  let convertedDate = formatDate5(newDate);
  const handleHideFemaleCreateModal = () => {
    setIsFemalePresent(false);
  };

  return (
    <div className="mvp__modals">
      <div
        style={{
          position: "fixed",
          top: `${femaleSuccessCreation ? "0" : "-120px"}`,
          left: 0,
          width: "100%",
          height: "100px",
          overflow: "auto",
          transition: "all 0.5s ease-in-out",
          zIndex: "99999",
        }}
      >
        <CustomSuccess
          message={`${t("listview.notificationMessages.obsCreated")}`}
        />
      </div>

      <Modal
        show={true}
        onHide={handleHideModalCreateModal}
        backdrop="static"
        centered
        className="mx-auto mvp__modals create__modal"
        keyboard={false}
        size="lg"
        dialogClassName="modal-size"
        aria-labelledby="example-custom-modal-styling-title"
      >
        <Modal.Header
          className="bgPrimary modal__header text-left  "
          style={{
            color: "white",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Modal.Title className="modal__header__title">
            {t("createObs")}
          </Modal.Title>
          <div className="">
            <span
              className="pointer"
              onClick={() => {
                handleShowConfirmModal();
              }}
            >
              <RxCross1 className="close-modal-icon" />
            </span>
          </div>
        </Modal.Header>

        {!showUploadUI ? (
          <>
            <form onSubmit={handleSubmit(onSubmit)}>
              <div
                className="modal-top"
                style={{ borderBottom: "0.3px solid #ebf0ec" }}
              >
                <div
                  className="modal-map"
                  style={{ width: "50%", height: "100%" }}
                >
                  {!newLocalLat && !newLocalLong ? (
                    <img
                      src={unavailableMapPlaceHolder}
                      style={{
                        height: "100%",
                        width: "100%",
                      }}
                      alt=""
                    />
                  ) : (
                    newLocalLat && newLocalLong && <ModalsMap />
                  )}
                </div>
                <div
                  className="modal-imgs "
                  style={{ width: "50%", height: "100%", position: "relative" }}
                >
                  <div
                    style={{
                      height: "100%",
                      width: "100%",
                    }}
                    className="d-flex justify-content-center align-items-center"
                  >
                    {images.length === 0 ? (
                      <img
                        onClick={() => {
                          setShowUploadUI(!showUploadUI);
                        }}
                        src={uploadImagePlaceHolder}
                        style={{
                          height: "100%",
                          width: "100%",
                          cursor: "pointer",
                        }}
                        alt=""
                      />
                    ) : (
                      <div
                        style={{
                          width: "100%",
                          height: "100%",
                          position: "relative",
                          backgroundImage: `url(${images[0].data_url})`,
                          backgroundRepeat: "no-repeat",
                          backgroundPosition: " center center",
                          backgroundSize: "cover",
                        }}
                      />
                    )}
                  </div>
                  {images && images.length !== 0 && (
                    <div
                      className="modal-photo-btn create"
                      onClick={() => {
                        setShowUploadUI(!showUploadUI);
                      }}
                    >
                      {images && images.length} + photos {">"}
                    </div>
                  )}
                </div>
              </div>

              <div className="container-fluid modalBody" ref={modalRef}>
                {/* OBS Display */}
                <div className="row ">
                  <div className="col-12 px-4 pt-1 ">
                    <span
                      className="f-bold me-2 single__obs__id"
                      // style={{ fontSize: "1.1rem" }}
                    >
                      Obs {currentProject && currentProject.id}
                    </span>
                    {displayObserVationType && (
                      <ObservationTypePill
                        displayObserVationType={displayObserVationType}
                      />
                    )}
                  </div>
                  <div className="col-5 text-end"></div>
                </div>

                {/* Project Name */}
                <div className="row px-3 modal__obs__details__row projectName">
                  <div className="col-5 modal__detail__title">
                    {t("projectName")}
                  </div>
                  <div className="col-7 modal__title__value author">
                    {currentProject && currentProject.name}
                  </div>
                </div>

                {/* Author */}
                <div className="row px-3 modal__obs__details__row projectName">
                  <div className="col-5 modal__detail__title">
                    {"Observer name"}
                  </div>
                  <div className="col-7 modal__title__value author">
                    {currentProject && currentProject.user}
                  </div>
                </div>

                {/* Date, Time */}

                <DateTimeSelector
                  errors={errors}
                  setChangeTracker={setChangeTracker}
                />

                {/* Coords */}
                <CoordinatesInputs
                  cordRef={cordRef}
                  setChangeTracker={setChangeTracker}
                  errors={errors}
                />

                {/* OBS Type */}
                <div className="row px-3 modal__obs__details__row mt-1">
                  <div className="col-5 modal__detail__title">
                    {t("obsType")} <sup>*</sup>
                  </div>
                  <div className="col-7 modal__title__value">
                    <select
                      {...register("observationType")}
                      placeholder="observation type"
                      id="observationType"
                      name="observationType"
                      className={`crud_select crud-input ${
                        errors.observationType ? "is-invalid" : ""
                      }`}
                      style={{ width: "101%" }}
                      onChange={(e) => {
                        setObsTypeState(e.target.value);
                        handleSetObservationType(
                          e.target.value,
                          observationList,
                          setDisplayObserVationType
                        );
                        setLocalObsTypeValidation(false);
                        setChangeTracker(true);
                      }}
                    >
                      {displayObserVationType ? (
                        <option>{displayObserVationType}</option>
                      ) : (
                        <option>{`${t("select")}`}</option>
                      )}

                      {observationList &&
                        observationList.map((s, i) => (
                          <option value={s.id} key={i}>
                            {s.name}
                          </option>
                        ))}
                    </select>

                    <p className="error">
                      {errors.observationType?.message
                        ? errors.observationType?.message
                        : localObsTypeValidation
                        ? `${t("required")}`
                        : ""}
                    </p>
                    {/* {localObsTypeValidation && (
                        <p className="error">This Field  is required</p>
                      )} */}
                  </div>
                </div>

                {/* Site, Segment */}

                <SiteSegmentInputs
                  errors={errors}
                  register={register}
                  setChangeTracker={setChangeTracker}
                />

                {/* Group / Family / Species */}
                <GroupFamSpecie
                  errors={errors}
                  register={register}
                  setChangeTracker={setChangeTracker}
                />

                {/* Life Status Slider
                <div
                  className="dead-alive  d-flex  px-3 py-2"
                  style={{
                    justifyContent: "space-between",
                    fontSize: "14px",
                    color: "#595959",
                    marginBottom: "5px",
                    background: "#fff",
                  }}
                >
                  <div className="col-5 modal__detail__title">{t("status")}</div>

                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <div
                      style={{
                        fontWeight: "bold",
                        marginRight: "15px",
                      }}
                    >
                      {status}
                    </div>
                    <Switch onChange={statusChange} />
                  </div>
                </div> */}

                {/* NEW NEST DYNAMIC BREAKPOINT*/}
                <div>
                  <div>
                    {(displayObserVationType === obsertionTypes.nest.VE ||
                      displayObserVationType === obsertionTypes.nest.VF) && (
                      <CreateNewNest
                        modalRef={modalRef}
                        errors={errors}
                        setChangeTracker={setChangeTracker}
                        siteSegmentList={siteSegmentList}
                      />
                    )}

                    {(displayObserVationType === obsertionTypes.female.VE ||
                      displayObserVationType === obsertionTypes.female.VF) && (
                      <CreateNewFemaleEncounter
                        errors={errors}
                        setChangeTracker={setChangeTracker}
                        setCrawlWidth={setCrawlWidth}
                        crawlWidth={crawlWidth}
                      />
                    )}

                    {/* Notes */}
                    <div className="row px-3 modal__obs__details__row mt-1">
                      <div className="col-5 modal__detail__title">
                        {"Notes"}
                      </div>
                      <div className="col-7 modal__title__value">
                        <input
                          type="text"
                          id="notes"
                          name="notes"
                          className={`crud_select crud-input ${
                            errors.notes ? "is-invalid" : ""
                          }`}
                          value={notes}
                          style={{ width: "101%" }}
                          onChange={(e) => {
                            setNotes(e.target.value);
                            setLocalNotesValidation(false);
                            setChangeTracker(true);
                          }}
                        />
                      </div>
                    </div>
                    <div
                      className=""
                      style={{
                        height: "100px",
                        width: "100%",
                      }}
                    ></div>
                  </div>
                </div>
              </div>

              <div
                className="modal-footers"
                style={{
                  borderRadius: "8px",
                }}
              >
                <div
                  className="d-flex justify-content-end"
                  style={{
                    width: "100%",
                  }}
                >
                  <div
                    className="modal_obs__btn"
                    onClick={() => {
                      handleShowConfirmModal();
                    }}
                  >
                    {t("cancelBtn")}
                  </div>
                  <div
                    // type="submit"
                    className="modal_obs__btn validate"
                    onClick={() => handleCreateObs()}
                  >
                    {t("createBtn")}
                  </div>
                </div>
              </div>
            </form>
          </>
        ) : (
          <ImagesUpload
            setChangeTracker={setChangeTracker}
            setShowUploadUI={setShowUploadUI}
            showUploadUI={showUploadUI}
            images={images}
            setImages={setImages}
            setImageData={setImageData}
          />
        )}
      </Modal>

      {/* {confirmExit && ( */}
      <ConfirmExit
        confirmExit={confirmExit}
        handleCreateObs={handleCreateObs}
        handleExitEdit={handleExitEdit}
        handleConfirmExit={handleConfirmExit}
      />

      {isFemalePresent && (
        <NewFemaleCreate handleHide={handleHideFemaleCreateModal} />
      )}
    </div>
  );
};

export default CreateObservation;
