import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { RxCross1 } from "react-icons/rx";
import { MapContainer, TileLayer } from "react-leaflet";
import "../../style.css";

import { ReactComponent as PlaceholderImage } from "../../../../assets/images/no-image.svg";
import calender from "../../../../assets/images/calender.png";
import watch from "../../../../assets/images/watch.png";
import backArrow from "../../../../assets/images/backArrow.png";

import { AiOutlineQuestionCircle } from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteObservaion,
  deleteObservationLocally,
  validatObservationLocally,
  validateUnValidatedObservations,
} from "../../../../actions/projectActions";
import Loading from "../../../LoadingError/Loading";

import { observationStyle } from "../../../../utils/TypeObsStyle";

import ConfirmationModal from "../../ConfirmationModal";
import ModalMarker from "../../../map/leafletMap/ModalMarker";
import { useTranslation } from "react-i18next";
import { useTranslateObsType } from "../../../../utils/useTranslateObservationType";
import { useLocation } from "react-router-dom/cjs/react-router-dom";
const DetailModal = ({
  editData,
  closeDetailModal,
  openIsEditModal,
  handleShowImageCarosel,
}) => {
  const handleCloseDetailModall = () => {
    closeDetailModal();
  };
  // Translation
  const { t } = useTranslation();

  // is user admin
  const userRoleOnProject = useSelector(
    (state) => state.userRoleOnParticularProject
  );
  const { role } = userRoleOnProject;

  // Edit modal elements

  const [editModalState, setEditModalState] = useState(true);
  const [status, setStatus] = useState("");
  const [showQAs, setShowQAs] = useState(false);

  const [currentImages, setCurrentImages] = useState([]);
  useEffect(() => {
    if (editData) {
      const values = Object.values(editData.images);
      const filteredImages = values.filter(
        (value) => value !== "NULL" && value !== ""
      );
      setCurrentImages(filteredImages);
      if (editData.dead === 0) {
        setStatus(`${t("alive")}`);
      } else {
        setStatus(`${t("dead")}`);
      }
    }
  }, [editData, t]);

  // Delete observation
  const [deleteObsConfirm, setDeleteObsConfirm] = useState(false);
  const handleCloseConfirm = () => {
    setDeleteObsConfirm(false);
  };

  const [showHideValidate, setShowHideValidate] = useState(false);
  useEffect(() => {
    const path = window.location.pathname;

    if (path === "/listview-unvalidate") {
      setShowHideValidate(true);
    } else {
      setShowHideValidate(false);
    }
  }, []);

  // delete function
  const deleteObservation = useSelector((state) => state.deleteObservation);
  const { deleteLoading } = deleteObservation;
  const dispatch = useDispatch();
  const handleDeleteObs = () => {
    dispatch(deleteObservaion(editData.id));
    dispatch(deleteObservationLocally([editData.id]));
    setDeleteObsConfirm(false);
    handleCloseDetailModall();
  };

  // Validate obs
  const handleValidateObservations = () => {
    dispatch(validateUnValidatedObservations([editData.id]));
    dispatch(validatObservationLocally([editData.id]));
    handleCloseDetailModall();
    // handleHideConfirmationModal();
  };

  // translate obsType
  const translatedObsType = useTranslateObsType(editData.TypeObservation);

  const location = useLocation();
  const [preventModalOpen, setPreventModalOpen] = useState(false);
  useEffect(() => {
    if (
      location.pathname === "/listview-validate" ||
      location.pathname === "/listview-unvalidate"
    ) {
      setPreventModalOpen(true);
    } else {
      setPreventModalOpen(false);
    }
  }, [location.pathname]);

  return (
    <div className="mvp__modals">
      <Modal
        show={true}
        backdrop="static"
        centered
        className="mx-auto mvp__modals view_modal"
        keyboard={false}
        size="lg"
        style={{
          borderTopLeftRadius: "20px",
        }}
        dialogClassName="modal-size"
        aria-labelledby="example-custom-modal-styling-title"
      >
        <Modal.Header
          className="bgPrimary text-left modal__header"
          style={{
            color: "white",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Modal.Title className="modal__header__title">
            {t("viewObs")}
          </Modal.Title>
          <div className="">
            <span
              className=" pointer"
              onClick={() => {
                handleCloseDetailModall();
              }}
            >
              <RxCross1 className="close-modal-icon" />
            </span>
          </div>
        </Modal.Header>
        {deleteLoading && (
          <div
            style={{
              position: "fixed",
              top: "50%",
              left: "50%",

              transform: "translate(-50%, -50%)}",
            }}
          >
            <Loading />
          </div>
        )}

        {editModalState ? (
          <>
            <div className="modal-top">
              <div
                className="modal-map"
                style={{ width: "50%", height: "100%", background: "grey" }}
              >
                <MapContainer
                  center={[
                    editData && editData.coordX,
                    editData && editData.coordY,
                  ]}
                  zoom={12}
                  scrollWheelZoom={true}
                  zoomControl={false}
                  layer-type="base"
                  style={{ width: "100%", height: "100%", zIndex: "1" }}
                >
                  <TileLayer
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                    attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                  />
                  <ModalMarker observation={editData} />
                </MapContainer>
              </div>
              {currentImages && currentImages.length === 0 ? (
                <div
                  className="modal-imgs "
                  style={{
                    width: "50%",
                    height: "100%",
                  }}
                >
                  <PlaceholderImage className="no-image" />
                </div>
              ) : (
                <div
                  className="modal-imgs "
                  style={{
                    width: "50%",
                    height: "100%",
                    position: "relative",
                    backgroundImage: `url(${currentImages[0]})`,
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: " center center",
                    backgroundSize: "cover",
                  }}
                ></div>
              )}
              {currentImages && currentImages.length !== 0 && (
                <div
                  className="modal-photo-btn"
                  onClick={() => {
                    setEditModalState(!editModalState);
                  }}
                >
                  {currentImages.length > 1 ? (
                    <>
                      <span className="mx-1">+</span> {currentImages.length - 1}
                    </>
                  ) : (
                    ""
                  )}

                  {currentImages.length > 1 ? (
                    <span className="mx-1">more</span>
                  ) : (
                    <>
                      {currentImages.length} <span className="mx-1">photo</span>
                    </>
                  )}
                  <svg
                    width="7"
                    height="12"
                    viewBox="0 0 7 12"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M0.276974 0.159762C0.0691454 0.372745 0.0691099 0.718125 0.27701 0.931144L5.22321 5.99987L0.276974 11.0689C0.0691454 11.2818 0.0691099 11.6272 0.27701 11.8402C0.484874 12.0533 0.821864 12.0533 1.02973 11.8402L6.35231 6.38555C6.45212 6.28325 6.50819 6.14453 6.50819 5.99987C6.50819 5.85522 6.45209 5.71645 6.35227 5.6142L1.02969 0.1598C0.821864 -0.0532551 0.484839 -0.053256 0.276974 0.159762Z"
                      fill="white"
                    />
                  </svg>
                </div>
              )}
            </div>
            <div className="modalBody  ">
              <div style={{ width: "100%" }}>
                <div className=" ">
                  <div className="row px-4 pt-3 pb-2 ">
                    <div className="col-12 ">
                      <span
                        className="f-bold  me-4"
                        style={{ fontSize: ".9rem" }}
                      >
                        Obs {editData && editData.id}
                      </span>
                      <span
                        className=" p-1   d-inline obs__type "
                        style={
                          editData.TypeObservation === "Threat"
                            ? observationStyle.Threat
                            : editData.TypeObservation === "Crawl"
                            ? observationStyle.Crawls
                            : editData.TypeObservation === "Animal"
                            ? observationStyle.Animal
                            : editData.TypeObservation === "Flash Observation"
                            ? observationStyle.FlashObs
                            : editData.TypeObservation === "Signs of presence"
                            ? observationStyle.SignsPresence
                            : editData.TypeObservation === "Female Encounter"
                            ? observationStyle.FemaleEn
                            : editData.TypeObservation === "Nest"
                            ? observationStyle.Nest
                            : observationStyle.noStyle
                          //  styles.noStyle
                        }
                      >
                        {translatedObsType}
                      </span>
                    </div>
                    <div className="col-5 text-end"></div>
                  </div>
                </div>
              </div>
              <div
                className="modal__obs__details  container-fluid "
                style={{ width: "100%" }}
              >
                <div className="row px-3  modal__obs__details__row projectName">
                  <div className="col-5 modal__detail__title">
                    {t("projectName")}
                  </div>
                  <div className="col-7 modal__title__value details">
                    {editData && editData.projectName}
                  </div>
                </div>
                <div className="row px-3 modal__obs__details__row ">
                  <div className="col-5 modal__detail__title">
                    {t("author")}
                  </div>
                  <div className="col-7 modal__title__value details author__eclipse__text">
                    {editData && editData.user}
                  </div>
                </div>
                <div className="row px-3 modal__obs__details__row ">
                  <div className="col-5 modal__detail__title">
                    {" "}
                    {t("dateTime")}
                  </div>
                  <div className="col-7 modal__title__value details d-flex ">
                    <div className="col-6  me-1 date__eclipe__text">
                      <img
                        src={calender}
                        alt=""
                        width={14}
                        className="me-2 mb-1"
                      />
                      {editData && editData.date.date.slice(0, 10)}
                    </div>
                    <div className="col-6 w-100">
                      <img
                        src={watch}
                        alt=""
                        width={14}
                        className="me-2 mb-1"
                      />
                      {editData && editData.date.date.slice(11, 16)}
                    </div>
                  </div>
                </div>
                {preventModalOpen && (
                  <div className="row px-3 modal__obs__details__row ">
                    <div className="col-5 modal__detail__title coords_mobile">
                      {t("coords")} <sup>*</sup>
                    </div>
                    <div className="col-7 modal__title__value details">
                      <div className="d-flex gap-1">
                        <div className="coords__eclipe__text">
                          <span>{editData && editData.coordX}</span>
                        </div>
                        <span>,</span>
                        <div className="coords__eclipe__text">
                          <span> {editData && editData.coordY}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                <div className="row px-3 modal__obs__details__row ">
                  <div className="col-5 modal__detail__title ">
                    {t("site")}, {t("segment")}
                  </div>
                  <div className="col-7 modal__title__value details ">
                    <div className="d-flex gap-1">
                      <div className="col-">
                        {" "}
                        {editData && editData.site}{" "}
                        {editData.segment ? " ," : ""}
                      </div>
                      <div className="col-">{editData && editData.segment}</div>
                    </div>
                  </div>
                </div>
                <div className="row px-3 modal__obs__details__row ">
                  <div
                    className="col-5 modal__detail__title coords_mobile"
                    style={{
                      fontStyle: "italic",
                    }}
                  >
                    {t("group")} / {t("family")} / {t("species")}
                  </div>
                  <div className="col-7 modal__title__value details ">
                    <div className="d-flex gap-1">
                      <div className="coords__eclipe__text">
                        <span>
                          {/* {editData && editData.group} */}

                          {editData && editData.group}
                        </span>
                      </div>
                      <span>/</span>
                      <div className="coords__eclipe__text">
                        <span>{editData && editData.family}</span>
                      </div>
                      <span>/</span>
                      <div className="coords__eclipe__text">
                        <span>{editData && editData.specie}</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row px-3 modal__obs__details__row ">
                  <div className="col-5 modal__detail__title">
                    {t("status")}
                  </div>
                  <div className="col-7 modal__title__value details">
                    <span>{status}</span>
                  </div>
                </div>
                <div
                  className="row px-3 modal__obs__details__row "
                  style={
                    {
                      // paddingBottom: "10px"
                    }
                  }
                >
                  <div className="col-5 modal__detail__title">
                    {t("patrol")}
                  </div>
                  <div className="col-7 modal__title__value details">
                    <span>{editData && editData.patrolId}</span>
                  </div>
                </div>

                {/* Q/A code   */}

                {/* {editData?.questionsAnwsers?.map((item, index) => {
                  const { content, question } = item;
                  const normalizedKeys = Object.keys(editData)?.map(
                    (key) =>
                      key.charAt(0).toUpperCase() + key.slice(1).toLowerCase()
                  );
                  const isTitleIncluded =
                    normalizedKeys.includes(
                      question.title.charAt(0).toUpperCase() +
                        question.title.slice(1).toLowerCase()
                    ) || question.title === "Notes";

                  let translatedContent = content;

                  // Translation logic based on nest status and language
                  if (question.title === "Nest Status") {
                    if (
                      translatedContent === "Nid non derangé" ||
                      translatedContent === "Nest Undisturbed"
                    ) {
                      translatedContent = t("qa.nest.nestUndisturbed"); // Fetch from en.json
                    } else if (
                      translatedContent === "Nest undisturbed lost" ||
                      translatedContent === "Nid perdu sans être dérangé"
                    ) {
                      translatedContent = t("qa.nest.nestUndisturbedLost"); // Fetch from fr.json
                    } else if (
                      translatedContent === "Nest partially lost" ||
                      translatedContent === "Nid en parti perdu"
                    ) {
                      translatedContent = t("qa.nest.nestPartiallyLost"); // Fetch from fr.json
                    } else if (
                      translatedContent === "Nest totally lost" ||
                      translatedContent === "Nid totallement perdu"
                    ) {
                      translatedContent = t("qa.nest.nestTotallyLost"); // Fetch from fr.json
                    }
                  }

                  if (isTitleIncluded) {
                    return (
                      <div
                        key={index}
                        className="row px-3 modal__obs__details__row"
                      >
                        <div className="col-5 modal__detail__title">
                          {question.title.charAt(0).toUpperCase() +
                            question.title.slice(1).toLowerCase()}
                        </div>
                        <div className="col-7 modal__title__value details ">
                          {translatedContent.charAt(0).toUpperCase() +
                            translatedContent.slice(1)}
                        </div>
                      </div>
                    );
                  }
                  return null;
                })} */}
                {(() => {
                  // Separate notes from other items and exclude questions with IDs 100 and 336
                  const notes = editData?.questionsAnwsers?.find(
                    (item) => item.question.title.toLowerCase() === "notes"
                  );
                  const otherItems = editData?.questionsAnwsers?.filter(
                    (item) =>
                      item.question.title.toLowerCase() !== "notes" &&
                      item.question.id !== 320 &&
                      item.question.id !== 336
                  );

                  return (
                    <>
                      {/* Display all items except notes and excluded questions */}
                      {otherItems?.map((item, index) => {
                        const { content, question } = item;
                        let translatedContent = content;

                        // Translation logic for Nest Status (keeping this part unchanged)
                        if (question.title === "Nest Status") {
                          if (
                            translatedContent === "Nid non derangé" ||
                            translatedContent === "Nest Undisturbed"
                          ) {
                            translatedContent = t("qa.nest.nestUndisturbed");
                          } else if (
                            translatedContent === "Nest undisturbed lost" ||
                            translatedContent === "Nid perdu sans être dérangé"
                          ) {
                            translatedContent = t(
                              "qa.nest.nestUndisturbedLost"
                            );
                          } else if (
                            translatedContent === "Nest partially lost" ||
                            translatedContent === "Nid en parti perdu"
                          ) {
                            translatedContent = t("qa.nest.nestPartiallyLost");
                          } else if (
                            translatedContent === "Nest totally lost" ||
                            translatedContent === "Nid totallement perdu"
                          ) {
                            translatedContent = t("qa.nest.nestTotallyLost");
                          }
                        }

                        return (
                          <div
                            key={index}
                            className="row px-3 modal__obs__details__row"
                          >
                            <div className="col-5 modal__detail__title">
                              {question.title.charAt(0).toUpperCase() +
                                question.title.slice(1).toLowerCase()}
                            </div>
                            <div className="col-7 modal__title__value details">
                              {translatedContent.charAt(0).toUpperCase() +
                                translatedContent.slice(1)}
                            </div>
                          </div>
                        );
                      })}

                      {/* Display notes at the bottom */}
                      {notes && (
                        <div className="row px-3 modal__obs__details__row">
                          <div className="col-5 modal__detail__title">
                            {notes.question.title.charAt(0).toUpperCase() +
                              notes.question.title.slice(1).toLowerCase()}
                          </div>
                          <div className="col-7 modal__title__value details">
                            {notes.content.charAt(0).toUpperCase() +
                              notes.content.slice(1)}
                          </div>
                        </div>
                      )}
                    </>
                  );
                })()}
              </div>
            </div>
            {role ? (
              <div
                className="modal-footers"
                style={{
                  borderRadius: "8px",
                }}
              >
                <div
                  className=" modal_obs__btn "
                  onClick={() => {
                    setDeleteObsConfirm(true);
                  }}
                >
                  {t("deleteBtn")}
                </div>
                <div
                  className=" modal_obs__btn "
                  onClick={() => {
                    openIsEditModal();
                    handleCloseDetailModall();
                  }}
                >
                  {t("editBtn")}
                </div>

                {showHideValidate && (
                  <div
                    className="  modal_obs__btn validate"
                    onClick={() => {
                      handleValidateObservations();
                    }}
                  >
                    {t("validateBtn")}
                  </div>
                )}
              </div>
            ) : (
              ""
            )}
          </>
        ) : showQAs ? (
          <>
            <Modal.Body>
              <div
                className="p-2"
                style={{
                  maxHeight: "100%",
                  overflowY: "auto",
                }}
              >
                <div className="create_image_modal ms-1">
                  <div>
                    <div className="d-flex justify-content-between align-items-center">
                      <div
                        onClick={() => {
                          setEditModalState(!editModalState);
                          setShowQAs(!showQAs);
                        }}
                        style={{
                          cursor: "pointer",
                        }}
                      >
                        <img src={backArrow} alt="back" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Modal.Body>
          </>
        ) : (
          <>
            <Modal.Body>
              <div className="p-2">
                <div className="create_image_modal">
                  <div>
                    <div className="d-flex justify-content-between align-items-center ms-1">
                      <div
                        onClick={() => {
                          setEditModalState(!editModalState);
                        }}
                        style={{
                          cursor: "pointer",
                        }}
                      >
                        <img src={backArrow} alt="back" />
                      </div>
                    </div>

                    <div className="">
                      <div
                        className={`modal__gallery__centering ${
                          currentImages.length > 1
                            ? "justify-content-center"
                            : "modal__gallery__centering__single"
                        }`}
                      >
                        {currentImages.length === 0 ? (
                          <div
                            className="d-flex"
                            style={{ height: "400px", gap: "1px" }}
                          >
                            <div>{t("noImgAvailable")}</div>
                          </div>
                        ) : (
                          currentImages &&
                          currentImages?.map((singleImg, index) => (
                            <>
                              <div
                                key={index}
                                className="  tableImage details "
                                style={{
                                  margin: "10px",
                                  backgroundImage: `url(${singleImg})`,
                                  backgroundRepeat: "no-repeat",
                                  backgroundSize: "cover",
                                  cursor: "pointer",
                                  position: "relative",
                                }}
                                onClick={() => {
                                  handleShowImageCarosel(editData);
                                  handleCloseDetailModall();
                                }}
                              />
                            </>
                          ))
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Modal.Body>
          </>
        )}
      </Modal>

      {deleteObsConfirm && (
        <ConfirmationModal
          cofirmSourceIcon={
            <AiOutlineQuestionCircle
              style={{
                fontSize: "3rem",
                fontWeight: "bold",
              }}
              color="red"
            />
          }
          message={t("deleteItems")}
          details={t("itemsDeleted")}
          btn1Text={t("cancelBtn")}
          btn2Text={t("deleteBtn")}
          color={"red"}
          handleHideConfirmationModal={handleCloseConfirm}
          handleConfirmAction={handleDeleteObs}
          closeConfirm={handleCloseConfirm}
        />
      )}
    </div>
  );
};

export default DetailModal;
